var Utils = {
  queryArray: function(selector, scope) {
    scope = scope || document.body;
    return Array.prototype.slice.call(scope.querySelectorAll(selector));
  },
  ready: function(func) {
    document.readyState !== "loading" ? func() : document.addEventListener("DOMContentLoaded", func)
  }
}

Utils.touch = {
  isSupported: "ontouchstart" in window || navigator.maxTouchPoints,
  isDragging: false
};

Utils.ready(function() {
  document.body.addEventListener("touchmove", function() {
    Utils.touch.isDragging = true;
  });
  document.body.addEventListener("touchstart", function() {
    Utils.touch.isDragging = false;
  });
});

Utils.supports = {
  pointerEvents: function() {
    var style = document.createElement("a").style;
    style.cssText = "pointer-events:auto";
    return style.pointerEvents === "auto";
  }()
};

function siteNavDropdowns(e) {
  var _this = this;
  this.container = document.querySelector(e);
  this.nav = this.container.querySelector(".site-navigation");
  this.container.classList.add("no-dropdown-transition");
  this.dropdownBackground = this.container.querySelector(".dropdown-background");
  this.dropdownBackgroundAlt = this.container.querySelector(".alt-background");
  this.dropdownContainer = this.container.querySelector(".dropdown-container");
  this.dropdownLinks = Utils.queryArray(".has-dropdown", this.nav);
  this.dropdownSections = Utils.queryArray(".dropdown-section", this.container).map(function(e) {
    return {
      el: e,
      name: e.getAttribute("data-dropdown"),
      content: e.querySelector(".dropdown-content")
    }
  });

  var pointerEvent = window.PointerEvent ? {
    end: "pointerup",
    enter: "pointerenter",
    leave: "pointerleave"
  } : {
    end: "touchend",
    enter: "mouseenter",
    leave: "mouseleave"
  };

  this.dropdownLinks.forEach(function(el, i) {
    el.addEventListener(pointerEvent.end, function(e) {
      e.preventDefault();
      e.stopPropagation();
      _this.toggleDropdown(el);
    });
    el.addEventListener(pointerEvent.enter, function(e) {
      if (e.pointerType == "touch") return;
      _this.stopCloseTimeout();
      _this.openDropdown(el);
    });
    el.addEventListener(pointerEvent.leave, function(e) {
      if (e.pointerType == "touch") return;
      _this.startCloseTimeout();
    });
  });

  this.dropdownContainer.addEventListener(pointerEvent.end, function(e) {
    e.stopPropagation();
  });
  this.dropdownContainer.addEventListener(pointerEvent.enter, function(e) {
    if (e.pointerType == "touch") return;
    _this.stopCloseTimeout();
  });
  this.dropdownContainer.addEventListener(pointerEvent.leave, function(e) {
    if (e.pointerType == "touch") return;
    _this.startCloseTimeout();
  });
  document.body.addEventListener(pointerEvent.end, function(e) {
    Utils.touch.isDragging || _this.closeDropdown();
  });
};

siteNavDropdowns.prototype.openDropdown = function(link) {
  var _this = this;
  if (this.activeDropdown === link) return;
  this.container.classList.add("overlay-active");
  this.container.classList.add("dropdown-active");
  this.activeDropdown = link;
  this.dropdownLinks.forEach(function(link){
    link.classList.remove("active");
  });
  link.classList.add("active");

  var dropdownName = link.getAttribute("data-dropdown"),
      dir = "left", width, height, content;
  
  this.dropdownSections.forEach(function(section) {
    section.el.classList.remove("active");
    section.el.classList.remove("left");
    section.el.classList.remove("right");
    if (section.name == dropdownName) {
      section.el.classList.add("active");
      dir = "right";
      width = section.content.offsetWidth;
      height = section.content.offsetHeight;
      content = section.content;
    } else {
      section.el.classList.add(dir);
    }
  });

  var baseWidth = 520,
      baseHeight = 400,
      scalarX = width / baseWidth,
      scalarY = height / baseHeight,
      bounds = link.getBoundingClientRect(),
      containerLeft = bounds.left + bounds.width / 2 - width / 2
  
  containerLeft = Math.round(Math.max(containerLeft, 10));
  clearTimeout(this.disableTransitionTimeout);
  this.enableTransitionTimeout = setTimeout(function() {
    _this.container.classList.remove("no-dropdown-transition");
  }, 50);
  this.dropdownBackground.style.transform = "translateX(" + containerLeft + "px) scaleX(" + scalarX + ") scaleY(" + scalarY + ")";
  this.dropdownContainer.style.transform = "translateX(" + containerLeft + "px)";
  this.dropdownContainer.style.width = width + "px";
  this.dropdownContainer.style.height = height + "px";
  var altHeight = content.children[0].offsetHeight / scalarY;
  this.dropdownBackgroundAlt.style.transform = "translateY(" + altHeight + "px)";
}

siteNavDropdowns.prototype.closeDropdown = function() {
  var _this = this;
  if (!this.activeDropdown) return;
  this.dropdownLinks.forEach(function(el) {
    el.classList.remove("active");
  });
  clearTimeout(this.enableTransitionTimeout);
  this.disableTransitionTimeout = setTimeout(function() {
    _this.container.classList.add("no-dropdown-transition");
  }, 50);
  this.container.classList.remove("overlay-active");
  this.container.classList.remove("dropdown-active");
  this.activeDropdown = undefined;
};

siteNavDropdowns.prototype.toggleDropdown = function(el) {
  this.activeDropdown === el ? this.closeDropdown() : this.openDropdown(el);
};

siteNavDropdowns.prototype.startCloseTimeout = function() {
  var _this = this;
  this.closeDropdownTimeout = setTimeout(function() {
    _this.closeDropdown();
  }, 50);
};

siteNavDropdowns.prototype.stopCloseTimeout = function() {
  clearTimeout(this.closeDropdownTimeout);
};

function siteNavPopup(e) {
  var _this = this,
      pointerEvent = Utils.touch.isSupported ? "touchend" : "click";
    
  this.activeClass = "popup-active";
  this.root = document.querySelector(e);
  this.link = this.root.querySelector(".root-link");
  this.popup = this.root.querySelector(".popup");
  this.closeButton = this.root.querySelector(".popup-close-button");

  this.link.addEventListener(pointerEvent, function(e) {
    e.stopPropagation();
    _this.togglePopup();
  });

  this.popup.addEventListener(pointerEvent, function(e) {
    e.stopPropagation();
  });

  if (this.closeButton) {
    this.closeButton.addEventListener(pointerEvent, function(e) {
      _this.closeAllPopups();
    });
  }

  document.body.addEventListener(pointerEvent, function(e) {
    if (!Utils.touch.isDragging) {
      _this.closeAllPopups();
    }
  }, false);
}

siteNavPopup.prototype.togglePopup = function() {
  var active = this.root.classList.contains(this.activeClass);
  this.closeAllPopups(true);
  if (!active) {
    this.root.classList.add(this.activeClass);
  }
}

siteNavPopup.prototype.closeAllPopups = function(e) {
  var activePopups = document.getElementsByClassName(this.activeClass);
  for (var i=0;i < activePopups.length;i++) {
    activePopups[i].classList.remove(this.activeClass);
  }
}

Utils.ready(function() {
  new siteNavDropdowns(".site-header");
  new siteNavPopup(".site-header .mobile-navigation");
});